import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DataInfo from "./dataInfo"

import Contact from "../components/contact"

class DataPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: 0,
    }

    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  updateDimensions() {
    let windowWidth =
      typeof window !== "undefined" ? window.visualViewport.width : 0
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0

    this.setState({ windowWidth, windowHeight })
  }

  render() {
    const { windowWidth, windowHeight } = this.state

    if ((windowWidth > 500) & (windowHeight > 500)) {
      return (
        <Layout rootPage={false} windowWidth={windowWidth}>
          <SEO title="Agustina Giraudy Data" />
          <DataInfo windowWidth={windowWidth} />
          <div style={footerStyle}>
            <Contact windowWidth={windowWidth} />
          </div>
        </Layout>
      )
    } else {
      return (
        <Layout rootPage={false} windowWidth={windowWidth}>
          <SEO title="Agustina Giraudy Data" />
          <DataInfo windowWidth={windowWidth} />
          <Contact windowWidth={windowWidth} />
        </Layout>
      )
    }
  }
}

const footerStyle = {
  position: "fixed",
  width: "100%",
  bottom: "0px",
}

export default DataPage
