import React, { Component } from "react"
import { Spring } from "react-spring/renderprops"

import headshot from "../images/data.jpg"

import { useStaticQuery, graphql } from "gatsby"

function FilesLoad() {
  const data = useStaticQuery(graphql`
    query DataQuery {
      daa: allFile(filter: { sourceInstanceName: { eq: "daa" } }) {
        totalCount
        edges {
          node {
            publicURL
            name
          }
        }
      }
      wwaic: allFile(filter: { sourceInstanceName: { eq: "wwaic" } }) {
        totalCount
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  console.log(data)

  return (
    <>
      <em>
        Democrats and Autocrats: Pathways of Subnational Undemocratic Regime
        Continuity within Democratic Countries (2015)
      </em>
      <br />

      {data.daa.edges.map(({ node }) => (
        <>
          <a href={node.publicURL} style={downloadStyle} download>
            {node.name}
          </a>
          <br />
        </>
      ))}

      <br />
      <em>
        Who Wants an Independent Court? Political Competition and Supreme Court
        Autonomy in the Argentine Provinces (1984-2008) (2015)
      </em>
      <br />

      {data.wwaic.edges.map(({ node }) => (
        <>
          <a href={node.publicURL} style={downloadStyle} download>
            {node.name}
          </a>
          <br />
        </>
      ))}
      <br />
      <a
        href="https://github.com/agustinagiraudy/data"
        target="_blank"
        style={linkStyle}
      >
        View data on Github
      </a>
    </>
  )
}

class DataInfo extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.windowWidth > 500) {
      return (
        <div style={sectionWrapper}>
          <div style={sectionStyle}>
            <div style={textBoxStyle}>
              <h1 style={pageHeader}>Data</h1>
              <p style={mainText}>
                <FilesLoad />
              </p>
            </div>
            <div style={imageBoxStyle}>
              <img src={headshot} alt="Headshot" style={imageStyle} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div style={sectionWrapper}>
          <div style={sectionStyle}>
            <div style={textBoxStyle}>
              <h1 style={pageHeaderMobile}>Data</h1>
              <p style={mainTextMobile}>
                <FilesLoad />
              </p>
            </div>
            <div style={imageBoxStyle}>
              <img src={headshot} alt="Headshot" style={imageStyle} />
            </div>
          </div>
        </div>
      )
    }
  }
}

const sectionWrapper = {
  margin: "0px 20px 0px 20px",
}

const sectionStyle = {
  maxWidth: "1160px",
  margin: "40px auto 20px auto",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
}

const pageHeader = {
  fontFamily: "Avant Garde",
  fontSize: "26px",
  margin: "20px 0px 40px 0px",
  color: "black",
}

const pageHeaderMobile = {
  ...pageHeader,
  fontSize: "22px",
  margin: "20px 0px 20px 0px",
}

const mainText = {
  fontFamily: "Caslon",
  fontSize: "22px",
  textAlign: "left",
}

const mainTextMobile = {
  ...mainText,
  fontSize: "19px",
  lineHeight: "19px",
}

const textBoxStyle = {
  maxWidth: "650px",
  textAlign: "left",
  flexShrink: "1",
}

const imageBoxStyle = {
  maxWidth: "470px",
  objectFit: "cover",
  flexShrink: "2",
}

const imageStyle = {
  position: "relative",
  width: "100%",
  height: "390px",
  objectFit: "cover",
  borderRadius: "4px",
  top: "-10px",
}

const downloadStyle = {
  textDecoration: "none",
  color: "#7395AE",
}

const linkStyle = {
  textDecoration: "none",
  color: "#7395AE",
}

export default DataInfo
